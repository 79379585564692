export function resolveSpecialText(product, onSale, t, isMobile) {
  const displayedText = [];
  const { availableForSale, tags } = product;
  const newTag = tags && tags.find((tag) => tag.toLowerCase() === "new");
  const bundle_2_15 = tags && tags.find((tag) => tag.toLowerCase() === "bundle-bedding-15" || tag.toLowerCase() === "bundle-bath-15");
  // const bundle_2_20 = tags && tags.find((tag) => tag.toLowerCase() === "essential-offer");
  const bundle_3_15 = tags && tags.find((tag) => tag.toLowerCase() === "buy3get15");
  const bundle_5_15 = tags && tags.find((tag) => tag.toLowerCase() === "bundle-socks-15");
  const bundle_3_30 = tags && tags.find((tag) => tag.toLowerCase() === "3-for-30");
  // const core_bundle_25 = tags && tags.find((tag) => tag.toLowerCase() === "core-bundle-25");
  const buy_3_100 = tags && tags.find((tag) => tag.toLowerCase() === "bundle-crew-3" || tag.toLowerCase() === "bundle-tanks-3");
  const buy_2_100 = tags && tags.find((tag) => tag.toLowerCase() === "bundle-heavycrew-2");
  const off_20 = tags && tags.find((tag) => tag.toLowerCase() === "NOTSET");
  const springCapsuleWardrobe =
    tags && tags.find((tag) => tag.toLowerCase() === "spring-capsule-wardrobe");

  // ARRAY OVERRIDES HERE

  if (product.tags.find((tag) => tag === "coming-soon")) {
    return [t("coming-soon")];
  }

  if (!availableForSale) {
    return [t("sold-out")];
  }

  if (springCapsuleWardrobe && !isMobile) {
    return [t("buy-2-and-save"), t("buy-3-or-more-and-save")];
  }

  // if (availableForSale && bundle_2_20) { displayedText.push(t("Buy 2+, Save 20%")); return displayedText; }
  if (availableForSale && newTag) { displayedText.push(t("new")); }
  if (availableForSale && off_20) { displayedText.push(t("20% Off")); }
  if (availableForSale && bundle_2_15) { displayedText.push(t("Buy 2+, Save 15%"));}
  if (availableForSale && bundle_3_15) { displayedText.push(t("Buy 3+, Save 15%")); }
  if (availableForSale && bundle_5_15) { displayedText.push(t("Buy 5+, Save 15%")); }
  if (availableForSale && bundle_3_30) { displayedText.push(t("3-for-30")); }
  if (availableForSale && buy_3_100) { displayedText.push(t("Buy 3 for $100")); }
  if (availableForSale && buy_2_100) { displayedText.push(t("Buy 2 for $100")); }
  // if (availableForSale && core_bundle_25) { displayedText.push(t("Buy 2+ Save 25%")); }
  if (availableForSale && onSale) return [t("sale")];


  return displayedText;
}
