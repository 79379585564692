import * as currencyService from "@services/format/currency";

export function resolvePrice(product, currency) {
  let price = null;
  if (product && product.variants && product.variants.length) {
    price = product.variants[0].presentmentPrices.filter(
      (presentmentPrice) => presentmentPrice.price.currencyCode === currency
    )[0];
  }

  let formattedPrice = "";
  if (price && price.price && price.price.amount) {
    formattedPrice = currencyService.formatPrice(price.price.amount, currency);
  }

  return { price, formattedPrice };
}

export function resolveStaticPrice(product, currency) {
  let price = null;

  if (product && product.variants && product.variants.length) {
    price = product.variants[0].sourceData.presentmentPrices.edges.filter(
      (presentmentPrice) => presentmentPrice.node.price.currencyCode === currency
    )[0];
  }

  let formattedPrice = "";
  if (price && price.node && price.node.price && price.node.price.amount) {
    formattedPrice = currencyService.formatPrice(price.node.price.amount, currency);
  }

  return { price, formattedPrice };
}
