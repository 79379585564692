import styles from "./Checkbox.module.css";

export const Checkbox = ({
  id,
  name,
  value,
  checked,
  onChange,
  label,
  wideRow
}) => {

  const checkboxStyles = `
    ${styles.input}
    ${wideRow ? styles.wideRow : ""}
  `;

  return (
    <label htmlFor={id} className={checkboxStyles}>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {label}
    </label>
  )
}