import { logError } from "@lib/logger";
import Cookies from "js-cookie";
// documentation here 
// https://help.loopreturns.com/article/147-shop-now-headless-on-store-setup
export function handleLoopReturnsParam() {
    if (window.location.search.includes('loop_total')) { 
        // clear checkout
        Cookies.remove('kotn_checkout');

        let paramsObject = null;    
        const params = new URLSearchParams(window.location.search); 
        /*   
        // Remove any non-loop related query params, then convert the query string   
        // to an object of key/value pairs to make it easier to work with   
        */
        paramsObject = [...params.entries()]
                        .filter(([key]) => 
                            key.startsWith('loop'))
                            .reduce((acc, [key, value]) => {       
                                return {         
                                    ...acc,         
                                    [key]: value       
                                };     
                            }, {});

                            localStorage.setItem(
                                'loopOnstoreParams', 
                                JSON.stringify(paramsObject)
                            ); 
        // Remove query params from the URL   
        window.history.replaceState({}, '', `${window.location.origin}${window.location.pathname}`); 
    }
}

export function getLoopReturnParams() {
    const savedParams = localStorage.getItem('loopOnstoreParams'); 
    return savedParams ? JSON.parse(savedParams) : null; 
}

export async function createLoopReturnsCart(variantIds) {
    try {
        const response = await fetch(`https://api.loopreturns.com/api/v1/cart`, {
            method: 'POST',
            headers: {
                'X-Authorization': '1272a4c51e7158fe975827eabcaa9055889d4881'
            },
            body: JSON.stringify({
                cart: variantIds
            })
        });
        const data = await response.json();
        return data.token;
    } catch (err) {
        logError(err);
    }
}