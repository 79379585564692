export function addBundleItems(lineItems, bundle) {
  let found = new Set();

  // Get current items
  let updatedItems = [];
  updatedItems = lineItems.map(({ node }) => {
    let { quantity: currentQuantity } = node;

    const { quantityAvailable } = node.variant;

    const item = bundle.find((item) => item.variant.id === node.variant.id);

    if (item) {
      // Update the current item in the checkout
      found.add(item);
      currentQuantity += item.quantity;
    }

    return {
      variantId: node.variant.id,
      quantity: currentQuantity,
      quantityAvailable,
      customAttributes: node.customAttributes,
    };
  });

  bundle.forEach((item) => {
    if (!found.has(item)) {
      // Add the item to the checkout
      let itemToAdd = {
        variantId: item.variant.id,
        quantity: item.quantity,
        quantityAvailable: item.variant.quantityAvailable,
      };
      if (item.specialNotes) {
        itemToAdd = {
          ...itemToAdd,
          customAttributes: [
            {
              key: "notes",
              value: item.specialNotes,
            },
          ],
        };
      }
      updatedItems.push(itemToAdd);
    }
  });

  return updatedItems;
}
