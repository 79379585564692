import { debounce } from "debounce";
import { facebookPixel } from "../facebookPixel";
import { googleAnalytics } from "../gtag";
import { pinterestAnalytics } from "../pinterest";
import { tiktokPixel } from "../tiktokPixel";

export function _search(data) {
  facebookPixel("track", "Search", {
    search_string: data,
  });

  googleAnalytics("event", "search", {
    search_term: data,
  });

  pinterestAnalytics("track", "search", {
    search_query: data,
  });

  tiktokPixel("Search", {
    query: data,
  });
}

// avoid recording every key stroke
export const search = debounce(_search, 1000);
