import { useQuery } from "react-query";
import graphqlFetch from "@lib/graphql-fetch";

const addressFields = `
  address1
  address2
  city
  company
  country
  countryCodeV2
  phone
  province
  provinceCode
  zip
  formattedArea
`;

const moneyFields = `
  amount
  currencyCode
`;

const variantFields = `
  product {
    handle,
    id
  }
  priceV2 { ${moneyFields} }
  compareAtPriceV2 { ${moneyFields} }
  image {
    altText
    originalSrc
    placeholder: transformedSrc(maxWidth: 100, preferredContentType: JPG)
  }
  selectedOptions {
    name
    value
  }
`;

const lineItemFields = `
  title
  quantity
  variant { ${variantFields} }
  originalTotalPrice { ${moneyFields} }
  discountedTotalPrice { ${moneyFields} }
`;

const orderFields = `
  id
  orderNumber
  processedAt
  fulfillmentStatus
  statusUrl
  shippingAddress { ${addressFields} }
  subtotalPriceV2 { ${moneyFields} }
  totalPriceV2 { ${moneyFields} }
  totalShippingPriceV2 { ${moneyFields} }
  totalTaxV2 { ${moneyFields} }
  lineItems(first: 250) {
    edges {
      node {
        ${lineItemFields}
      }
    }
  }
`;

const accountQuery = `
  query getAccountInfo($customerAccessToken: String!, $numAddresses: Int!, $numOrders: Int!) {
    customer(customerAccessToken: $customerAccessToken) {
      firstName
      lastName
      displayName
      defaultAddress { ${addressFields} }
      email
      phone
      id
      addresses(first: $numAddresses) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node { ${addressFields} }
        }
      }
      orders(first: $numOrders, reverse: true) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node { ${orderFields} }
        }
      }
    }
  }
`;

export async function getAccountInfo(customerAccessToken) {
  const data = await graphqlFetch(accountQuery, {
    variables: {
      customerAccessToken,
      numAddresses: 20,
      numOrders: 250,
    },
  });

  return data;
}

export function useAccountInfo(customerAccessToken) {
  return useQuery(
    ["getAccountInfo", customerAccessToken],
    () => getAccountInfo(customerAccessToken),
    {
      enabled: !!customerAccessToken,
      refetchOnWindowFocus: false,
    }
  );
}
