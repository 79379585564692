export async function AlgoliaEventsApi(payload = {}) {
  const ALGOLIA_APPLICATION_ID = 'H2A4NDL5ID';
  const ALGOLIA_API_KEY = '671c8479ac37384d6d8a059ac07d2b09';
  try {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
        await fetch(`https://insights.algolia.io/1/events?x-algolia-application-id=${ALGOLIA_APPLICATION_ID}&x-algolia-api-key=${ALGOLIA_API_KEY}`, {
            method: 'POST',
            body: JSON.stringify(payload)
        });
        
    } else {
      console.log("[algolia-tracking]: track", payload);
    }
  } catch (err) {
    console.log("[algolia-tracking]: error", err, payload);
  }
}