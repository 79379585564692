import { googleAnalytics } from '../gtag';

export function viewItemList({ offset, filteredProducts, collection } ) {

    googleAnalytics('event', 'view_item_list', {
        item_list_id: collection.handle,
        item_list_name: collection.title,
        items : filteredProducts.map((product, index) => {
            let price = 0;
            try {
                price = product.variants[0].priceV2.amount;
            } catch (error) {
                price = product.variants[0].presentmentPrices.filter(
                    (presentmentPrice) => presentmentPrice.price.currencyCode === 'CAD'
                )[0].price.amount
            }
            return {
                id: product.id,
                name: product.title,
                brand: product.vendor,
                category: collection.title,
                list_position: offset + index,
                quantity: 1,
                price
            }
          })
      });
}
