export const formatPrice = (price, currency = "USD") => {
  let locale = "en-US";

  if (typeof window !== "undefined") {
    // browser code
    locale = window.navigator.language;
  }

  const options = {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const formatter = new Intl.NumberFormat(locale, options);
  return formatter.format(price).replace(".00", "");
};
