import PropTypes from "prop-types";
import styles from "./styles/drawer.module.css";

export function Drawer({ children, isOpen = false, close }) {
  const drawerStyles = `${styles.container} ${isOpen ? styles.open : ""}`;

  return (
    <>
      <aside className={drawerStyles} onMouseLeave={close}>
        {children}
      </aside>
    </>
  );
}

Drawer.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
};
