import { googleAnalytics } from "../gtag";
import { klaviyoAnalytics } from "../klaviyo";
import { facebookPixel } from "../facebookPixel";
import { tiktokPixel } from "../tiktokPixel";
import formatVariantPrice from "@components/Product/lib/format-variant-price";

export function beginCheckout({ checkout }) {
  const items = checkout.lineItems.edges.map(({ node: item }) => {

    // const amount = item.variant.priceV2.amount;
    // const discountAmount = 
    // // const { amount, discountAmount } = formatVariantPrice(
    // //   {
    // //     presentmentPrices: item.variant.priceV2.amount,
    // //   },
    // //   checkout.subtotalPriceV2.currencyCode,
    // //   item.quantity,
    // //   item.discountAllocations
    // // );
    return {
      item_id: item.variant.id,
      item_name: item.title,
      item_type: item.type,
      item_variant: item.variant.selectedOptions[0]?.value,
      item_sku: item.variant.sku,
      price: amount,
      currency: checkout.subtotalPriceV2.currencyCode,
      quantity: item.quantity,
    };
  });


  googleAnalytics("event", "begin_checkout", {
    currency: checkout.currencyCode,
    items,
  });

  // facebookPixel("track", "InitiateCheckout", {
  //   content_ids: items.map((item) => item.item_sku),
  //   currency: checkout.subtotalPriceV2.currencyCode,
  //   num_items: checkout.lineItems.edges.length,
  //   value: checkout.subtotalPriceV2.amount,
  // });

  klaviyoAnalytics("track", "Started Checkout", {
    $event_id: checkout.id + "_" + Math.floor(Date.now() / 1000),
    $value: checkout.subtotalPriceV2.amount,
    ItemNames: items.map((item) => item.item_name),
    CheckoutURL: checkout.webUrl,
    Items: items.map((item) => {
      return {
        ProductID: item.item_id,
        ProductName: item.item_name,
        Quantity: item.quantity,
        ItemPrice: item.price,
        RowTotal: item.price,
      };
    }),
  });

  tiktokPixel("InitiateCheckout", {
    contents: items.map((item) => {
      return {
        content_id: item.item_id,
        content_type: "product",
        content_name: item.item_name,
        quantity: item.quantity,
        price: item.price,
      };
    }),
    currency: "CAD",
  });
}
