export const TIKTOK_PIXEL_ID = "C590A0NB3D4MMMSM77S0";

export function tiktokPixel(type, payload = {}) {
  try {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
      //@ts-ignore
      ttq.track(type, payload);
    } else {
      console.log("[tiktok-tracking]: track", type, payload);
    }
  } catch (err) {
    console.log("[tiktok-tracking]: error", err, type, payload);
  }
}
