import { googleAnalytics } from "../gtag";
import { getSize, getColor } from "@components/Product/lib/product-utils";

export function selectContent({ bundle }) {
  googleAnalytics("event", "view_item_list", {
    item_list_id: bundle[0].slug,
    item_list_name: bundle[0].slug,
    items: bundle.map(({ product, variant, slug }, index) => ({
      id: variant.sku,
      name: product.productReferenceV2.title,
      list_name: slug,
      list_position: index + 1,
      category: product.productReferenceV2.type,
      variant: getColor(variant),
      size: getSize(variant),
      quantity: 1,
      price: variant.priceV2.amount,
    })),
  });
}
