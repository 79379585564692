export function handleize(str) {
  if (str) {
    return str
      .toLowerCase()
      .replace(/[^\w\u00C0-\u024f]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }
  return str;
}

export function capitalize(str) {
  if (str) {
    return str.toLowerCase().replace(/[^-'\s]+/g, function (word) {
      return word.replace(/^./, function (first) {
        return first.toUpperCase();
      });
    });
  }

  return str;
}

export function oxford(arr, conjunction, ifempty) {
  let l = arr?.length;
  if (!l) return ifempty;
  if (l < 2) return arr[0];
  if (l < 3) return arr.join(` ${conjunction} `);
  arr = arr.slice();
  arr[l - 1] = `${conjunction} ${arr[l - 1]}`;
  return arr.join(", ");
}
