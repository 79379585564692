import { facebookPixel } from "../facebookPixel";
import { googleAnalytics, GA_TRACKING_ID, GA4_TRACKING_ID } from "../gtag";
import { pinterestAnalytics } from "../pinterest";
import { tiktokPixel } from "../tiktokPixel";

export function viewPage(data) {
  facebookPixel("track", "PageView");
  facebookPixel("track", "ViewContent", {
    content_name: data.pageTitle,
    content_category: data.pageCategory,
  });
  googleAnalytics("event", "page_view", {
    page_title: data.pageTitle,
    page_location: data.pageLocation,
    page_path: data.pagePath,
    page_category: data.pageCategory,
    send_to: GA_TRACKING_ID,
  });
  googleAnalytics("event", "page_view", {
    page_title: data.pageTitle,
    page_location: data.pageLocation,
    page_path: data.pagePath,
    page_category: data.pageCategory,
    send_to: GA4_TRACKING_ID,
  });

  tiktokPixel("ViewContent", {
    content_name: data.pageTitle,
    content_category: data.pageCategory,
  });

  // pinterest does not have a view product event
  // so to avoid a double count we dont fire pagevisit on view_item event
  if (data.noFire === "pinterest") return;
  pinterestAnalytics("track", "pagevisit");
}
