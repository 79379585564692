export function resolveDiscount(discount, t) {
  let text = null;
  if (discount) {
    text = (
      <span>
        {t("discount-label")} {discount}
      </span>
    );
  }

  return text;
}
