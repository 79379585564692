import * as Sentry from "@sentry/browser";

export function logError(error) {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
    //@ts-ignore
    Sentry.captureException(error);
  } else {
    console.error("[logError]: error: ", error);
  }
}

export const logErrorMessage = (error) => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
    //@ts-ignore
    Sentry.captureMessage(error);
  } else {
    console.error("[logError]: error: ", error);
  }
}
