export function resolveFooterNotice(location, total, t) {
  if (location && (location.country == "CA" || location.country == "US")) {
    if (total >= 75) {
      return <p>{t("footer-message")}</p>;
    } else {
      return (
        <p>
          {t("footer-spend")} ${(100 - total).toFixed(2)} {t("footer-more")}
        </p>
      );
    }
  }

  return null;
}
