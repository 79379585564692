export const cleanLink = (string) => {
  return string.replace(/^(https?|ftp|mailto|tel):\/?\/?/gi, "");
};

export const removeGender = (string) => {
  return string?.replace(/(^([Ww]o)?[mM]en['’]s\s)+/gi, "");
};

export const titleCase = (string) => {
  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const firstLetterUpper = (string) => {
	var newString = string.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g,function(c){return c.toUpperCase()});
  return newString;
}

const blockTextFormatters = [
  { pattern: /\B'/g, replacement: "‘" }, // Smart Left Single Quotes
  { pattern: /\b'/g, replacement: "’" }, // Smart Right Single Quotes
  { pattern: /^"/g, replacement: "“" }, // Smart Left Single Quotes
  { pattern: /"$/g, replacement: "”" }, // Smart Left Single Quotes
  { pattern: /\s([^\s<]+)\s*$/g, replacement: "\u00A0$1" }, // Orphans
];

export const blockText = (a) =>
  blockTextFormatters.reduce((a, f) => a?.toString().replace(f.pattern, f.replacement), a);

const sizeFormatters = [
  {
    pattern: /^XS\b/gi,
    replacement: "Extra-small",
  },
  {
    pattern: /^S\b/gi,
    replacement: "Small",
  },
  {
    pattern: /^M\b/gi,
    replacement: "Medium",
  },
  {
    pattern: /^L\b/gi,
    replacement: "Large",
  },
  {
    pattern: /^XL\b/gi,
    replacement: "Extra-large",
  },
  {
    pattern: /^TP\b/gi,
    replacement: "Très petit",
  },
  {
    pattern: /^P\b/gi,
    replacement: "Petit",
  },
  {
    pattern: /^M\b/gi,
    replacement: "Moyen",
  },
  {
    pattern: /^G\b/gi,
    replacement: "Grand",
  },
  {
    pattern: /^TG\b/gi,
    replacement: "Très grand",
  },
];

export const formatSize = (a) =>
  sizeFormatters.reduce((a, f) => a?.toString().replace(f.pattern, f.replacement), a);

export const handleize = (str) => {
  return str
    ? str
        .toLowerCase()
        .replace(/[^\w\u00C0-\u024f]+/g, "-")
        .replace(/^-+|-+$/g, "")
    : str;
};

export const formatTitle = (str) => {
  const wordsWithApostrophes = /^([\w]+['’][\w]+)\s/g;
  const everythingAfterName = /( in )(.*)/g;

  return str.replace(wordsWithApostrophes, "").replace(everythingAfterName, "");
};

export function stringNum(num) {
  let value;
  switch (true) {
    case num <= 5:
      value = "a few";
      break;
    case num === 6:
      value = "six";
      break;
    case num === 7:
      value = "seven";
      break;
    case num === 8:
      value = "eight";
      break;
    case num === 9:
      value = "nine";
      break;
    case num === 12:
      value = "a dozen";
      break;
    default:
      value = num;
  }
  return value;
}

export const wordCount = (str) => {
  return str.split(" ").length;
};
