const breakpoints = [35, 60];

export const relativeLinkRegex = RegExp("(http)s?://(www.|beta.)?kotn.c(om?|a)/", "gi");

export const mq = breakpoints.map((bp) => `@media (min-width: ${bp}em)`);

export const CURRENCIES = ["CAD", "USD", "AUD", "EUR", "GBP"];
export const CURRENCY_TO_COUNTRY_CODE = {
    "CAD": "CA",
    "USD": "US",
    "AUD": "AU",
    "EUR": "FR", // France just cause its a country in the eurozone,
    "GBP": "GB"
}
