import { facebookPixel } from "../facebookPixel";
import { googleAnalytics } from "../gtag";
import { pinterestAnalytics } from "../pinterest";
import { tiktokPixel } from "../tiktokPixel";

export function viewCollection(collection) {
  let event_category = collection.handle.match(/^((wo)?mens?|home|bundles)/i);
  if (event_category) {
    event_category = event_category[0];
  } else {
    event_category = "other";
  }

  const event_label = collection.handle.replace(`${event_category}-`, "");

  facebookPixel("track", "ViewContent", {
    content_name: "Collection",
    content_category: collection.handle,
  });

  googleAnalytics("event", "view_collection", {
    event_category: event_category,
    event_label: event_label,
  });

  pinterestAnalytics("track", "viewcategory");

  tiktokPixel("ViewContent", {
    content_name: "Collection",
    content_category: collection.handle,
  });
}
