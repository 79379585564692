import * as currencyService from "@services/format/currency";

export function resolveSubtotal(checkout) {
  if (checkout && checkout.subtotalPriceV2.amount > 0)
    return currencyService.formatPrice(
      checkout.subtotalPriceV2.amount,
      checkout.subtotalPriceV2.currencyCode
    );

  return null;
}
