export function resolveColour(options) {
  if (options) {
    const colour = options.find((option) =>
      option.name.toLowerCase().match(/([Cc](o|ou)l(o|ou|eu)r)/g)
    );
    if (colour && colour.values && colour.values.length) {
      return colour.values[0];
    }
  }

  return null;
}
