import * as currencyService from "@services/format/currency";

export function resolveSale(price, currency) {
  let salePrice = null;
  if (
    price &&
    price.compareAtPrice &&
    price.compareAtPrice.amount &&
    price.price &&
    price.price.amount
  ) {
    if (parseInt(price.compareAtPrice.amount, 10) > parseInt(price.price.amount, 10)) {
      salePrice = currencyService.formatPrice(price.compareAtPrice.amount, currency);
    }
  }

  return salePrice;
}
