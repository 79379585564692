import { getProductSlugFromRedis } from "@lib/redis";
import { handleize } from "@services/format/string";

function isSize(option) {
  if (option && option.name) {
    return option.name.toLowerCase() === "size";
  }
  return {};
}

const getSize = (node) => node.selectedOptions.find(isSize)?.value;

export async function getProductURL(handle, variant = null) {
  const response = await getProductSlugFromRedis(handle);

  if (!response) {
    return null;
  }

  if (!variant) return response.slug;

  const size = getSize(variant);

  if (size) {
    const formattedSize = handleize(size);
    return {
      ...response.slug,
      size: formattedSize,
    };
  }

  return response.slug;
}
