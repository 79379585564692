import { googleAnalytics } from "../gtag";
import { pinterestAnalytics } from "../pinterest";
import { tiktokPixel } from "../tiktokPixel";

export function viewEmailCapture(data) {
  googleAnalytics("event", `viewed`, {
    event_category: "emailCapture",
    event_label: `${data.source}`,
  });
}

export function submitEmailCapture(data) {
  const source = data.source
    .toLowerCase()
    .replace(/[^\w\u00C0-\u024f]+/g, "_")
    .replace(/^-+|-+$/g, "");

  googleAnalytics("event", "submitted", {
    event_category: "emailCapture",
    event_label: `${source}`,
  });

  pinterestAnalytics("track", "signup", {
    lead_type: `${source}`,
  });

  tiktokPixel("SubmitForm");
}
