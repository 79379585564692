import formatVariantPrice from "@components/Product/lib/format-variant-price";

export function resolveTotal(checkout) {
  if (checkout) {
    let total = checkout.lineItems.edges.reduce((sum, item) => {
      const { amount } = formatVariantPrice(
        item.node.variant,
        checkout.subtotalPriceV2.currencyCode,
        item.node.quantity,
        item.node.discountAllocations
      );
      if (item.node.variant.product.handle.toLowerCase() === "gift-card") {
        return sum + amount;
      }
      return sum;
    }, 0);
    total = checkout.subtotalPriceV2.amount - total;
    return total;
  }

  return 0;
}
