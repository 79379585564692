import PropTypes from "prop-types";
import { getShopifyNextImage } from "@lib/getImages";

export function Image({ title, image }) {
  return getShopifyNextImage(
    image.originalSrc,
    300,
    432,
    `(max-width: 75rem) 300, (max-width: 45rem) 300, 300`,
    `Image of ${title}`,
    image.placeholder
  );
}

Image.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    altText: PropTypes.string,
    originalSrc: PropTypes.string.isRequired,
  }).isRequired,
};
