export async function searchProducts(searchTerm, currency) {
  const body = {
    searchTerm: searchTerm,
    currency: currency,
  };
  try {
    const response = await fetch(`/api/search`, {
      body: JSON.stringify(body),
      method: "POST",
      headers: { Accept: "application/json" },
    });
    const data = await response.json();

    return data;
  } catch (error) {
    console.error(error.stack || error);
    return null;
  }
}

export async function getCollectionFromRedis(collectionHandle, page) {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_CONSUMER_SERVICE_API}/collections/${collectionHandle}?${page == 1 ? `page=1` : ""}`,

      {
        headers: { Accept: "application/json" },
      }
    );
    const { data } = await response.json();
    return data.products;
  } catch (error) {
    console.error(error.stack || error);
    return [];
  }
}

export async function getProductSlugFromRedis(productHandle) {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_CONSUMER_SERVICE_API}/products/${productHandle}`,
      {
        headers: { Accept: "application/json" },
      }
    );
    const { data } = await response.json();

    return data;
  } catch (error) {
    console.error(error.stack || error);
    return null;
  }
}

export async function getAllProductsFromRedis() {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_CONSUMER_SERVICE_API}/products`, {
      headers: { Accept: "application/json" },
    });
    const { data } = await response.json();

    return data;
  } catch (error) {
    console.error(error.stack || error);
    return null;
  }
}

function getProductsFromRecommendations(recommendations, type) {
  // TODO go through every recommendation provided by depict
  // TODO use handle to get product from kotn consumer micro service
  // TODO make sure to store the recommendation id to the object
  return recommendations.map(async (recommendation) => {
    const redisProduct = await getProductSlugFromRedis(recommendation.handle);
    if (redisProduct && redisProduct.slug) {
      redisProduct.slug.recommendation = recommendation;
      redisProduct.slug.recommendation.type = type;
    }
    return redisProduct?.slug;
  });
}

export async function parseRecommendations(recommendations = [], type) {
  const products = await Promise.all(getProductsFromRecommendations(recommendations, type));
  return products.filter((p) => p !== null && p !== undefined);
}
