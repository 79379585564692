const getColourInfo = (shopifyProducts) => {
  return shopifyProducts.map((product) => {
    const { colour, handle, id } = product;
    return {
      title: colour && colour.title,
      titleFr: colour && colour.title_fr,
      hex: colour && colour.colour.hex,
      handle: handle,
      id: id,
      tags: colour?.tags ? colour.tags.map(({label, value}) => value) : []
    };
  });
};

export function resolveColours(product) {
  if (product.sanity && product.sanity.shopifyProducts) {
    return getColourInfo(product.sanity.shopifyProducts);
  }

  return null;
}
