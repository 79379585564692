import { facebookPixel } from '../facebookPixel';
import { googleAnalytics } from '../gtag';

export function customizeProduct({ category, value, variant }) {
    facebookPixel("track", "CustomizeProduct", { 
        variant: category,
        value: value,
        sku: variant.sku
    });
    googleAnalytics('event', 'customize_product', {
        event_category: category,
        event_label: value,
        sku: variant.sku
    });
}