import fetch from "isomorphic-unfetch";
import { parseRecommendations } from "@lib/redis";

const NUMBER_OF_RECOMMENDATIONS = 8;

export async function fetchRecommendations(variant_ids, type) {
  let productRecommendations = [];

  const product_id_variable = type === 'checkout_multi' ? 'product_ids' : 'product_id';
  const endpoint = type === 'checkout_multi' ? "/recommend/products/products" : "/recommend/products/product";
  
  try {
    const response = await fetch(`https://api.depict.ai/v2${endpoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        tenant: "kotn",
        market: "global",
        type,
        [`${product_id_variable}`]: variant_ids,
      }),
    });

    if (!response.ok) {
      throw Error(response.statusText);
    }

    const data = await response.json();

    // TODO fetch product data from redis based on response from depict api
    productRecommendations = await parseRecommendations(
      data.displays.slice(0, NUMBER_OF_RECOMMENDATIONS),
      type
    );
    return productRecommendations;
  } catch (err) {
    console.log("[fetch-recommendations] Error", err);
    return productRecommendations;
  }
}
