export const PINT_TRACKING_ID = 2617919571137;

export function pinterestAnalytics(event, type, payload) {
  try {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
      //@ts-ignore
      pintrk(event, type, payload);
    } else {
      console.log("[pinterest-analytics]: event: ", event, type, payload);
    }
  } catch (err) {
    console.log("[pinterest-analytics]: error", err);
  }
}
