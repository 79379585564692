import { urlFor, client } from "./sanity";
import Img from "next/image";
import { useNextSanityImage } from "@hooks/useNextSanityImage";

export const getImages = (img, sizes) => {
  const imageLarge = urlFor(img)
    .auto("format")
    .size(sizes.large.width, sizes.large.height)
    .fit("max")
    .url();
  const imageMedium = urlFor(img)
    .auto("format")
    .size(sizes.medium.width, sizes.medium.height)
    .fit("max")
    .url();
  const imageSmall = urlFor(img)
    .auto("format")
    .size(sizes.small.width, sizes.small.height)
    .fit("max")
    .url();
  return { large: imageLarge, medium: imageMedium, small: imageSmall };
};

export const getPictureElement = (images, sizes, altText = "Image of Kotn Product") => (
  <picture>
    <source data-srcset={images.large} media="(min-width: 75em)" />
    <source data-srcset={images.medium} media="(min-width: 45em)" />
    <source data-srcset={images.small} />
    <img
      data-sizes="auto"
      data-src={images.small}
      width={sizes.small}
      height={sizes.small}
      className="lazyload"
      alt={altText}
    />
  </picture>
);

const shopifyImageLoader = ({ src, width = "", format = "progressive.jpg" }) => {
  const lastIndex = src.lastIndexOf(".");
  const parseSrc = src.split("?");

  return `${src.substr(0, lastIndex)}_${width}x.${format}?${parseSrc[1]}`;
};

export function getShopifyNextImage(
  imgSrc,
  width,
  height,
  sizes,
  alt,
  blurDataURL,
  priority = false,
  objectFit = "cover",
  layout = "responsive"
) {
  if (layout === "fill") {
    return (
      <Img
        loader={shopifyImageLoader}
        src={imgSrc}
        layout={layout}
        objectFit={objectFit}
        objectPosition={`${imgSrc.hotspot?.x}px ${imgSrc.hotspot?.y}px`}
        placeholder="blur"
        blurDataURL={blurDataURL}
        priority={priority}
        sizes={sizes}
        alt={alt}
      />
    );
  }
  return (
    <Img
      loader={shopifyImageLoader}
      src={imgSrc}
      width={width}
      height={height}
      layout={layout}
      objectFit={objectFit}
      placeholder="blur"
      blurDataURL={blurDataURL}
      priority={priority}
      sizes={sizes}
      alt={alt}
    />
  );
}

export function getSanityNextImage(
  img,
  sizes,
  alt,
  objectFit = "cover",
  priority = "false",
  layout = "responsive"
) {
  const imageProps = useNextSanityImage(client, img);

  if (layout === "fill") {
    return (
      <Img
        src={imageProps.src}
        loader={imageProps.loader}
        blurDataURL={imageProps.blurDataURL}
        layout="fill"
        priority={priority}
        objectFit={objectFit}
        objectPosition={`${img.hotspot?.x}px ${img.hotspot?.y}px`}
        placeholder="blur"
        alt={alt}
      />
    );
  }

  return (
    <Img
      {...imageProps}
      layout={layout}
      priority={priority}
      objectFit={objectFit}
      placeholder="blur"
      sizes={`(max-width: 75rem) ${sizes.large}, (max-width: 45rem) ${sizes.medium}, ${sizes.small}`}
      alt={alt}
    />
  );
}
