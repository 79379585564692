export function klaviyoAnalytics(event, type, payload) {
  try {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
      //@ts-ignore
      if (event === "identify") {
        klaviyo([event, payload]);
      } else {
        klaviyo([event, type, payload]);
      }
    } else {
      console.log("[klaviyo-tracking]: event ", event, type, payload);
    }
  } catch (err) {
    console.log("[klaviyo-tracking]: error ", event, type, payload);
  }
}
