export function updateItem(lineItems, item) {
  let items = lineItems.map(({ node }) => {
    if (node.id === item.itemId) {
      // Remove or update the item
      item.quantityAvailable = node.variant.quantityAvailable;
      item.customAttributes = node.customAttributes;
      return item.quantity <= 0 ? [] : [item];
    }
    return [
      {
        variantId: node.variant.id,
        quantity: node.quantity,
        quantityAvailable: node.variant.quantityAvailable,
        customAttributes: node.customAttributes,
      },
    ];
  });
  items = [].concat(...items);

  return items;
}
