import styles from "../styles/lineitem.module.css";

export function resolveSpecialNotes(notes) {
  let specialNotes = null;
  if (notes) {
    specialNotes = notes.find((note) => note.key === "notes");
  }

  if (specialNotes) {
    return <div className={styles.savings}>{specialNotes.value}</div>;
  }

  return null;
}
