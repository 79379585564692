import { useMemo } from "react";

import { urlFor } from "@lib/sanity";

import { handleize } from "@lib/formatString";

export const nodeToId = (id) =>
  Buffer.from(id, "base64")
    .toString("binary")
    .match(/[0-9]+$/g);

const isSize = (option) => {
  if (option && option.name) {
    return option.name.toLowerCase() === "size";
  }
  return false;
};

const isColor = (option) => {
  if (option && option.name) {
    return option.name.toLowerCase().match(/([Cc](o|ou)l(o|ou|eu)r)/g);
  }
  return false;
};

export const getSize = (node) => node.selectedOptions.find(isSize)?.value;

export const getOption = (node, name) =>
  node.selectedOptions.find((option) => option.name.toLowerCase() === name.toLowerCase())?.value;

export const getColor = (node) => node.selectedOptions.find(isColor)?.value;

export function getNavigation(product, selected, finalSale, isFrench) {
  const colours = product.shopifyProducts.map((shopifyProduct) => {
    const { handle, id, archived, options } = shopifyProduct.productReferenceV2;
    if (shopifyProduct.productColour) {
      const { colour, swatch, title, title_fr } = shopifyProduct.productColour;
      const label = (isFrench && title_fr) ? title_fr : title;

      const swatchImage = swatch
        ? urlFor(swatch).auto("format").size(36, 36).fit("max").url()
        : colour.hex;

      return {
        id: id,
        key: id,
        value: handleize(title.toLowerCase()),
        labelText: label,
        fill: swatchImage,
        colour: colour.hex,
        handle: handle,
        selected: selected ? id === selected.productReferenceV2.id : false,
        archived,
        isDisabled: false,
        finalSale: finalSale.includes(id) || false,
      };
    } else {
      const value = id + options[0].values[0]?.toLowerCase();
      return {
        id: id,
        key: id,
        value: value,
        labelText: options[0].name,
        handle: handle,
        selected: selected ? id === selected.productReferenceV2.id : false,
        archived,
        isDisabled: false,
        finalSale: finalSale.includes(id) || false,
      };
    }
  });

  return colours;
}
function getMedia(media) {
  // 1. Use a Map to avoid duplicated images, product variants may be
  // using the default image of the product
  const images = new Map();

  // 2. Append the images that are assigned to a variant
  if (media) {
    media.forEach((m) => {
      images.set(m.id, m);
    });
  }

  // // 3. Append any remaining images that are in the product and not assigned to a variant
  // product.images.edges.forEach(({ node }) => {
  //   images.set(node.originalSrc, node);
  // });

  return images;
}

export function useMedia(media) {
  return useMemo(() => getMedia(media), [media]);
}

export function getVariantsMetadata(variants) {
  const colors = new Set();
  const colorsBySize = new Map();

  variants.forEach((variant) => {
    const size = getSize(variant);
    const color = getColor(variant);

    if (color) colors.add(color);
    if (size) {
      const sizeColors = colorsBySize.get(size) || [];

      if (color) sizeColors.push(color);

      colorsBySize.set(size, sizeColors);
    }
  });

  return { colors, colorsBySize };
}

export function isOpenBox(variant) {
  const skukeys = variant['sku'].split('.');
  // The Season key for size greater than 900 is reserved for returned re sale items
  if ( Number(skukeys[skukeys.length-1]) >= 900 ) {
    return true
  }
  return false;
}

export function getVariantOptions(variants, selectedVariant) {
  console.log('VARIANTS', variants)
  const variantOptions = new Map();
  variants.forEach(variant => {
    variant.selectedOptions.forEach(({ name, value, sku }) => {
      const options = variantOptions.get(name) || [];
      if (!options.find((option) => option.sku === sku)) {
        const isSelected = variant.sku === selectedVariant.sku;
        options.push({
          id: variant.id,
          key: value.toLowerCase(),
          value: value.toLowerCase(),
          labelText: value,
          sku: variant.sku,
          isOpenBox: isOpenBox(variant),
          option: name,
          selected: isSelected,
          isDisabled: !variant.availableForSale,
        });
        variantOptions.set(name, options);
      }
    });
  });
  return variantOptions;
}