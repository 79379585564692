import { useQuery } from "react-query";
import { fetchRecommendations } from "../lib/fetch-recommendations";

export function useRecommendations(variant_ids, type) {
  const {
    data: recommendations,
    isError,
    error,
    isLoading,
  } = useQuery(["recommendations", variant_ids], () => fetchRecommendations(variant_ids, type));

  return { isError, error, isLoading, recommendations };
}
