const getSize = (variant) => {
  const size = variant.selectedOptions.filter(
    (option) => option.name === "Size" || option.name === "Shoe Size"
  )[0];
  return {
    size: size && size.value,
    isOpenBox: size.value.includes('Open Box'),
    available: variant.availableForSale,
  };
};

export function resolveSizes(variants) {
  try {
    if (variants) {
      const _variants = variants.map((variant) => getSize(variant));
      return  _variants.sort((a,b) => (a.isOpenBox > b.isOpenBox) ? 1 : ((b.isOpenBox > a.isOpenBox) ? -1 : 0))
    }
    return null;
  } catch (err) {
    return null;
  }


}
