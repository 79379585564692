import PropTypes from "prop-types";
import { Spinner } from "@components/Spinner";
import { VisuallyHidden } from "@components/VisuallyHidden";
import styles from "./button.module.css";
export function Button({
  children,
  type = "button",
  description,
  descriptionText,
  handleClick,
  value = null,
  variant = "primary",
  full = false,
  isLoading = false,
  isDisabled = false,
  additionalStyles = ""
}) {
  const buttonStyles = `
    ${styles.base}
    ${variant === "primary" ? styles.primary : ""}
    ${variant === "secondary" ? styles.secondary : ""}
    ${variant === "tertiary" ? styles.tertiary : ""}
    ${full ? styles.full : ""}
    ${additionalStyles ? additionalStyles : ""}
`;

  return (
    <>
      <button
        type={type}
        aria-describedby={description}
        className={buttonStyles}
        onClick={handleClick}
        value={value}
        disabled={isDisabled}
      >
        {isLoading ? <Spinner /> : children}
      </button>
      <VisuallyHidden id={description}>{descriptionText}</VisuallyHidden>
    </>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  value: PropTypes.any,
  variant: PropTypes.string,
  full: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
