// import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";
import { createPreviewSubscriptionHook, createClient } from "next-sanity";

const options = {
  // Find your project ID and dataset in `sanity.json` in your studio project
  dataset: process.env.SANITY_DATASET,
  projectId: process.env.SANITY_PROJECT_ID,
  useCdn: process.env.NODE_ENV === "production",
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION,
  // TODO: @mudassirijaz786; for later preview testing
  //   perspective: "published",
  //   token: process.env.SANITY_AUTH_TOKEN,
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
};

export const client = createClient(options);

export const previewClient = createClient({
  dataset: process.env.SANITY_DATASET,
  projectId: process.env.SANITY_PROJECT_ID,
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION,
  useCdn: false,
  withCredentials: true,
  token: process.env.SANITY_AUTH_TOKEN,
});

// Set up the live preview subscription hook
export const usePreviewSubscription = createPreviewSubscriptionHook(options);

const imageBuilder = imageUrlBuilder(client);

export const urlFor = (source) => {
  return imageBuilder.image(source);
};
