export * from "./CartDrawer";
export const TOTE = {
    id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDMyMDE0MTM5NDAwMw==',
    title: 'Kotn Logo Tote',
    // this variable is due to an artifact in the updateItem logic
    itemId:"Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzQwMzIwMTQxMzk0MDAzMD9jaGVja291dD0yZGY1YjNmMzg2OTBlYTFlN2FiMDdhYWJiYmExZDI0ZA==",
    sku: '30.8.21.16.77.14.0.0',
    selectedOptions: [{
      "name": "Colour",
      "value": "Natural"
  },
  {
      "name": "Size",
      "value": "O/S"
  }],
    quantity: 1
  }
