import fetch from "isomorphic-unfetch";

export default async function graphqlFetch(query, { variables = {} } = {}, isFrench = false) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Shopify-Storefront-Access-Token": process.env.SHOPIFY_STOREFRONT_TOKEN,
  };

  if (isFrench) {
    headers["Accept-Language"] = "fr";
  }
  const res = await fetch(process.env.SHOPIFY_STORE_URI, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  const json = await res.json();

  if (json.errors) {
    throw new Error(json.errors);
  }

  return json.data;
}

export async function graphqlAdminFetch(query, { variables = {} } = {}) {
  const res = await fetch(process.env.SHOPIFY_STORE_ADMIN_URI, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Shopify-Access-Token": process.env.SHOPIFY_ADMIN_PASSWORD,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  const json = await res.json();

  if (json.errors) {
    throw new Error(json.errors);
  }

  return json.data;
}
