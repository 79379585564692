import { klaviyoAnalytics } from "../klaviyo";
import { facebookPixel } from "../facebookPixel";
import { tiktokPixel } from "../tiktokPixel";
import { googleAnalytics } from "../gtag";
import { pinterestAnalytics } from "../pinterest";
import { extractProductId } from "./lib/extract-product-id";
import { getSize, getColor, nodeToId } from "@components/Product/lib/product-utils";
import { depictEvent } from "@lib/depict-client";

export function addToCart({ productGroup, variant, checkout }) {
  googleAnalytics("event", "add_to_cart", {
    currency: "CAD",
    value: checkout.subtotalPriceV2.amount,
    items: [
      {
        id: variant.sku,
        name: variant.title,
        variant: getColor(variant),
        brand: variant.vendor,
        size: getSize(variant),
        category:
          productGroup.shopifyProducts[0].productReferenceV2?.store.productType ??
          productGroup.shopifyProducts[0].type,
        price: variant.priceV2 ? variant.priceV2.amount : variant.presentmentPrices[0].price.amount,
        quantity: "1",
      },
    ],
  });

  facebookPixel("track", "AddToCart", {
    content_type:
      productGroup.shopifyProducts[0].productReferenceV2?.store.productType ??
      productGroup.shopifyProducts[0].type,
    content_ids: [variant.sku],
    content_name: variant.title,
    content_color: getColor(variant),
    content_size: getSize(variant),
    currency: "CAD",
    value: variant.priceV2 ? variant.priceV2.amount : variant.presentmentPrices[0].price.amount,
  });

  klaviyoAnalytics("track", "Added to Cart", {
    value: checkout.subtotalPriceV2.amount,
    AddedItemProductName: variant.title,
    AddedItemProductID: variant.sku,
    AddedItemSKU: parseInt(nodeToId(variant.id)),
    AddedItemPrice: variant.priceV2 ? variant.priceV2.amount : variant.presentmentPrices[0].price.amount,
    AddedItemType:
      productGroup.shopifyProducts[0].productReferenceV2?.store.productType ??
      productGroup.shopifyProducts[0].type,
    AddedItemQuantity: 1,
    ItemNames: variant.title,
    CheckoutURL: checkout ? checkout.webUrl : null,
    Items: [
      {
        ProductName: variant.title,
        ProductID:
          productGroup.shopifyProducts[0].productReferenceV2?.store.id ??
          productGroup.shopifyProducts[0].id,
        SKU: variant.sku,
        Categories: [
          productGroup.shopifyProducts[0].productReferenceV2?.store.productType ??
            productGroup.shopifyProducts[0].type,
        ],
        URL: window.location.href,
        Brand: productGroup.shopifyProducts[0].vendor,
        Price: variant.priceV2 ? variant.priceV2.amount : variant.presentmentPrices[0].price.amount,
      },
    ],
  });

  pinterestAnalytics("track", "AddToCart", {
    value: variant.priceV2 ? variant.priceV2.amount : variant.presentmentPrices[0].price.amount,
    product_id: extractProductId(
      productGroup.shopifyProducts[0].productReferenceV2?.store.id ??
        productGroup.shopifyProducts[0].id
    ),
    order_quantity: 1,
    currency: "CAD",
  });

  tiktokPixel("AddToCart", {
    content_id: extractProductId(
      productGroup.shopifyProducts[0].productReferenceV2?.store.id ??
        productGroup.shopifyProducts[0].id
    ),
    content_type: "product",
    content_name: variant.title,
    content_category:
      productGroup.shopifyProducts[0].productReferenceV2?.store.productType ??
      productGroup.shopifyProducts[0].type,
    quantity: 1,
    price: variant.priceV2 ? variant.priceV2.amount : variant.presentmentPrices[0].price.amount,
    value: checkout.subtotalPriceV2.amount,
    currency: "CAD",
  });

  depictEvent('addToCart', extractProductId(
    productGroup.shopifyProducts[0].productReferenceV2?.store.id ??
      productGroup.shopifyProducts[0].id
  ));
}
