import {DPC} from "@depict-ai/dpc";


export function depictEvent(event, payload) {
    const dpc = new DPC("KOTN", {market: "se"});
    try {
        if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
            dpq(event, payload);
        } else {
            console.log('[Depict Event]', event, payload);
        }
    } catch (err) {
        console.log('[Depict Event] error', err);
    }  
}